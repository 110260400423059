import { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { title: '', username: '', content: '', posts: [] };

    this.getPosts = this.getPosts.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.getPosts();
  }

  getPosts() {
    (async () => {
      let resp = await fetch("https://workers.mashplants.workers.dev/");
      resp = await resp.json();
      this.setState({ posts: resp });
      // console.log(resp);
      // return resp;
    })();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault()
    let s = this.state;
    fetch('https://workers.mashplants.workers.dev', {
      method: 'POST',
      body: JSON.stringify(s),
    });
    this.setState(s);
    // this.setState({ title: s.title, username: s.username, content: s.content, posts: s.posts });
    this.getPosts();
  }

  render() {
    console.log(this.state.posts);
    return (
      <div>
        <h1>Posts</h1>
        {this.state.posts.map((post) => (
          <div>
            <h2 align="center">title: {post.title}</h2>
            <h2 align="right">username: {post.username}</h2>
            <body>content: {post.content}</body>
          </div>
        ))}
        <form onSubmit={this.handleSubmit}>
          <label>
            title:
            <input type="text" name="title" value={this.state.title} onChange={this.handleChange} />
          </label>
          <label>
            username:
            <input type="text" name="username" value={this.state.username} onChange={this.handleChange} />
          </label>
          <label>
            content:
            <input type="text" name="content" value={this.state.content} onChange={this.handleChange} />
          </label>
          <input type="submit" value="提交" />
        </form>
      </div>
    );
  }
}

export default App;